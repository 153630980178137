import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { BreadCrumb, Loader } from '../../../../../Common';
import UsersList from '../../../../UserManagement/Components/Users';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  DOWNLOAD_USER_LOGS,
  DOWNLOAD_USER_LOGS_EMPTY,
  DOWNLOAD_USER_REPORT,
  DOWNLOAD_USER_REPORT_EMPTY,
  GET_CLIENT_USER,
} from '../../../../../../store/actions';
import { Link } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders } from '../../../../../Config';
import { Constant } from '../../../../../Helpers/constant';
import UpdateUserPermissionModal from './Components/Modals/UpdateUserPermission';
import User from './Components/Modals/CreateUser';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import axios, { AxiosResponse } from 'axios';

const initialState = {
  from: '',
  to: '',
};

const UserManagementAdib = () => {
  const skip = useRef(0);
  const dispatch = useDispatch();

  const flatpickrRef = useRef(null);

  const [clientUsers, setClientUsers] = useState([]);
  const [typedAdminName, setTypedAdminName] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUserPermissionModalOpen, setIsUserPermissionModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState(initialState);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Client Users
  const { users, usersLoading } = useSelector((state: any) => ({
    users: state?.GetClientUser?.data,
    usersLoading: state?.GetClientUser?.loading,
  }));

  const { createUser } = useSelector((state: any) => ({
    createUser: state.CreateAdibClient.data,
  }));

  // Update User Status
  const { updateUserStatus } = useSelector((state: any) => ({
    updateUserStatus: state?.UpdateUserStatus?.data,
  }));

  // Download User Logs
  const { downloadUserLogs, downloadUserLogsLoading } = useSelector((state: any) => ({
    downloadUserLogs: state?.DownloadUserLogs?.data,
    downloadUserLogsLoading: state?.DownloadUserLogs?.loading,
  }));

  // Download User Report
  const { downloadUserReport, downloadUserReportLoading } = useSelector((state: any) => ({
    downloadUserReport: state?.DownloadUserReport?.data,
    downloadUserReportLoading: state?.DownloadUserReport?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (users?.admins?.length > 0) {
      const newAdminUsers = users?.admins?.map(user => {
        return {
          ...user,
          userShortName: makeUserShortName(user?.firstName, user?.lastName),
        };
      });
      setClientUsers([...clientUsers, ...newAdminUsers]);
    }
  }, [users]);

  useEffect(() => {
    const list = document.querySelectorAll('.team-list');
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
        document.getElementById('list-view-button').classList.add('active');
        document.getElementById('grid-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.add('list-view-filter');
          el.classList.remove('grid-view-filter');
        });
      } else {
        document.getElementById('grid-view-button').classList.add('active');
        document.getElementById('list-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.remove('list-view-filter');
          el.classList.add('grid-view-filter');
        });
      }
    }
  }, []);

  useEffect(() => {
    if (createUser || updateUserStatus) {
      skip.current = 0; // Reset skip for fresh data
      setClientUsers([]); // Clear existing user list
      dispatch({
        type: GET_CLIENT_USER,
        payload: {
          urlParams: {
            pageIndex: skip.current,
            pageSize: 10,
            organization_id: 12,
          },
        },
      });
    }
  }, [createUser, updateUserStatus, dispatch]);

  useEffect(() => {
    if (flatpickrRef.current) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;

      flatpickrInstance.config.onOpen.push(() => {
        const calendarContainer = flatpickrInstance.calendarContainer;

        if (!calendarContainer.querySelector('.custom-options')) {
          const customOptions = document.createElement('div');
          customOptions.className = 'custom-options';
          Object.assign(customOptions.style, {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            margin: '0', // Remove extra spacing
            padding: '10px',
            backgroundColor: '#FFFFFF', // Background for better visibility
            position: 'absolute', // Allow positioning next to the calendar
            top: '0', // Align with the calendar's top
            right: 'calc(100% + 1px)', // Position it to the right of the calendar
            zIndex: '9999', // Ensure it appears above other elements
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          });

          const options = ['Today', 'Last 7 Days', 'Last 14 Days', 'Custom Range'];

          options.forEach(option => {
            const button = document.createElement('button');
            Object.assign(button.style, {
              padding: '11px 10px',
              fontSize: '0.8rem',
              margin: '10px 0',
              backgroundColor: '#3F5189',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              width: '122px',
            });

            button.textContent = option;

            button.onmouseenter = () => (button.style.backgroundColor = '#5A6FA1');
            button.onmouseleave = () => (button.style.backgroundColor = '#3F5189');

            button.addEventListener('click', () => handleCustomRange(option, flatpickrInstance));
            customOptions.appendChild(button);
          });

          calendarContainer.parentNode.style.position = 'relative'; // Ensure parent allows positioning
          calendarContainer.appendChild(customOptions);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (downloadUserLogs || downloadUserReport) {
      dispatch({
        type: DOWNLOAD_USER_LOGS_EMPTY,
      });
      dispatch({
        type: DOWNLOAD_USER_REPORT_EMPTY,
      });
    }
  }, [downloadUserLogs, downloadUserReport]);

  const handleCustomRange = (option, flatpickrInstance) => {
    let startDate = null;
    let endDate = null;

    switch (option) {
      case 'Today':
        startDate = endDate = new Date();
        break;
      case 'Last 7 Days':
        startDate = new Date(new Date().setDate(new Date().getDate() - 7));
        endDate = new Date();
        break;
      case 'Last 14 Days':
        startDate = new Date(new Date().setDate(new Date().getDate() - 14));
        endDate = new Date();
        break;
      default:
        return;
    }
    // Set selected range and close calendar
    let updatedState = null;
    updatedState = {
      ...filter,
      from: moment(startDate).format('Y-MM-DD'),
      to: moment(endDate).format('Y-MM-DD'),
    };

    setFilter(updatedState);
    flatpickrInstance.close();
  };

  /* ---------------------------- GET CLIENT USERS ---------------------------- */
  const fetchClientUsers = () => {
    skip.current += 10;
    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  };

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectAdminUser = event => {
    setClientUsers([
      {
        ...event?.completeData,
        userShortName: makeUserShortName(event?.completeData?.firstName, event?.completeData?.lastName),
      },
    ]);
    setTypedAdminName(event);
  };

  /* ---------------------------- SHORT USERNAME HANDLER ---------------------------- */
  const makeUserShortName = (firstName: string, lastName: string) => {
    return `${firstName[0] ?? ''}${lastName[0] ?? ''}`;
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */

  const loadAdminUsers = async (search: string) => {
    try {
      const response: AxiosResponse = await axios({
        method: 'GET',
        url: `${baseUrl}/admin/v1/organization-admins?email=${search}&organization_id=${12}`,
        headers: {
          Authorization: Constant?.CLIENT_TOKEN,
          'Project-Code': `ADMIN_ACCESS`,
        },
        params: {
          searchName: search === '' ? null : search, // Changed `name` to `searchName`
        },
        withCredentials: true,
      });

      const options = response.data.admins.map(data => ({
        value: data.id,
        label: `${data.firstName ?? ''} ${data.lastName ?? ''}`,
        completeData: data,
      }));

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      console.error('Error fetching announcement types:', error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const removeSearchedUser = () => {
    setClientUsers([]);
    setTypedAdminName(null);
    skip.current = 0;

    dispatch({
      type: GET_CLIENT_USER,
      payload: {
        urlParams: {
          pageIndex: skip.current,
          pageSize: 10,
          organization_id: 12,
        },
      },
    });
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    console.log('check', date[0], date[1]);

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          from: moment(new Date(date[0])).format('Y-MM-DD'),
          to: moment(new Date(date[1])).format('Y-MM-DD'),
        };
        setFilter(updatedState);
        break;
    }
  };

  useEffect(() => {
    if (downloadUserLogsLoading || downloadUserReportLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadUserLogsLoading, downloadUserReportLoading]);

  return (
    <div className="page-content">
      {isUserPermissionModalOpen && (
        <UpdateUserPermissionModal selectedUser={selectedUser} onCloseHandler={() => setIsUserPermissionModalOpen(false)} />
      )}
      {isUserModalOpen && <User onCloseHandler={() => setIsUserModalOpen(false)} />}
      {loading && <Loader />}
      <Container fluid className="position-relative">
        <BreadCrumb title="Dump Download" navigationby={() => {}} navigation={[`Dashboard`, `Users`]} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
          className="mb-3"
        >
          <Flatpickr
            ref={flatpickrRef}
            className="form-control"
            onChange={date => datePickHandler(`createdAtRange`, date)}
            value={[filter?.from, filter?.to]}
            placeholder="Select Range"
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              maxDate: 'today',
            }}
            style={{ width: '210px' }}
          />
        </div>

        <Card>
          <CardBody style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <AsyncPaginate
                debounceTimeout={500}
                value={typedAdminName}
                loadOptions={loadAdminUsers}
                onChange={e => onSelectAdminUser(e)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Enter Name..."
                styles={{}}
              />

              <div>
                {typedAdminName && (
                  <Button
                    color="danger"
                    id="grid-view-button"
                    className="btn btn-soft-danger nav-link btn-icon fs-14 active"
                    onClick={removeSearchedUser}
                  >
                    <i className="ri-close-fill"></i>
                  </Button>
                )}
              </div>
            </div>

            {hasPermission(PermissionConstants.ALL_PERMISSIONS.ADIB_SUPERADMIN) && (
              <div className="d-flex gap-2">
                <span
                  className="btn btn-primary mb-1"
                  onClick={() => {
                    dispatch({
                      type: DOWNLOAD_USER_LOGS,
                      payload: {
                        urlParam: {
                          ...filter,
                          downloadType: 1,
                        },
                      },
                    });
                  }}
                >
                  <div className="me-2">
                    <i className=" ri-download-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>User Logs
                  </div>
                </span>

                <span
                  className="btn btn-primary mb-1"
                  onClick={() => {
                    dispatch({
                      type: DOWNLOAD_USER_REPORT,
                      payload: {
                        urlParam: {
                          ...filter,
                          downloadType: 2,
                          organization_id: 12,
                        },
                      },
                    });
                  }}
                >
                  <div className="me-2">
                    <i className=" ri-download-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Audit Reports
                  </div>
                </span>
                <span
                  className="btn btn-primary mb-1"
                  onClick={() => {
                    setIsUserModalOpen(true);
                  }}
                >
                  <div className="me-2">
                    <i className="ri-add-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Add User
                  </div>
                </span>
              </div>
            )}
          </CardBody>
        </Card>

        <Row>
          <Col lg={12}>
            <div>
              <InfiniteScroll dataLength={clientUsers?.length} next={fetchClientUsers} hasMore={typedAdminName === null ? true : false} loader>
                <Row className="team-list grid-view-filter">
                  {clientUsers?.map((adminUser, key) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img src={adminUser.backgroundImg} alt="" className="img-fluid" />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col lg={4} className="col">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  {adminUser.userImage != null ? (
                                    <img src={adminUser.userImage} alt="" className="img-fluid d-block rounded-circle" />
                                  ) : (
                                    <div className={'avatar-title rounded-circle bg-soft-' + adminUser.bgColor + ' text-' + adminUser.textColor}>
                                      {adminUser.userShortName}
                                    </div>
                                  )}
                                </div>
                                <div className="team-content">
                                  <h5 className="fs-16 mb-1">{adminUser.name}</h5>
                                  {/* <p className="text-muted mb-0">
                                                                    {adminUser.jobPosition}
                                                                </p> */}
                                  <p className="text-muted mb-0">{adminUser.email}</p>
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} className="col">
                              <Row className="text-muted text-center">
                                <Col xs={12} className="border-end border-end-dashed border-start border-start-dashed">
                                  <h5 className="mb-1">{adminUser.projectCount}</h5>
                                  <p className="text-muted mb-0">Projects</p>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className="col">
                              <div
                                className="text-center btn btn-light view-btn"
                                onClick={() => {
                                  setIsUserPermissionModalOpen(true);
                                  setSelectedUser(adminUser);
                                }}
                              >
                                Edit Permissions
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}

                  {usersLoading && (
                    <Col lg={12}>
                      <div className="text-center mb-3">
                        <Link to="#" className="text-success">
                          <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                          Loading
                        </Link>
                      </div>
                    </Col>
                  )}
                </Row>
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserManagementAdib;
