import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { GET_COUPONS } from '../../../../../../../store/actions';
import Select from 'react-select';

const UniqueCouponFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
  
    serviceName: '',
    lotNumber: '',
    uploadedBy: '',
    status: '',
    uploadedOn: '',
    expiryDate: '',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const couponStatus = ['In-Process', 'Uploaded', 'Assigning In-process', 'Assigned'];
  const couponStatusOptions = couponStatus.map((status, index) => ({
    value: index,
    label: status,
  }));
  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    console.log('lll',e)
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
     

      case 'serviceName':
        updatedState = {
          ...filter,
          serviceName: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'lotNumber':
        updatedState = {
          ...filter,
          lotNumber: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      case 'status':
        updatedState = {
          ...filter,
          status: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      case 'uploadedBy':
        updatedState = {
          ...filter,
          uploadedBy: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;
      

      default:
      // do nothing
    }
  };
   /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
   const inputSelectHandler = (label, data) => {
    let updatedState = {...filter};
    if (label === `couponStatus`) {
      updatedState = {
        ...filter,
        status: data,
      };
    }
    console.log('react-select', data, updatedState)

    setFilter(updatedState);
  };
  /* ---------------------------- INPUT datepicker HANDLER ---------------------------- */
    const datePickHandler = (flag, date) => {
      let updatedState = null;
  
      switch (flag) {
        case 'expiryDate':
          updatedState = {
            ...filter,
            expiryDate: moment(new Date(date[0])).format('YYYY-MM-DD'),
          };
          setFilter(updatedState);
          validationCheck(updatedState);
          break;
  
        case 'uploadedOn':
          updatedState = {
            ...filter,
            uploadedOn: moment(new Date(date[0])).format('YYYY-MM-DD'),
          };
          setFilter(updatedState);
          validationCheck(updatedState);
          break;
        default:
        // do nothing
      }
    
    };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };
    console.log(filterValue);
    props.filterHandler({ ...filterValue });
    
    props.onCloseClick();
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
    dispatch({
      type: GET_COUPONS,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
    props.setCurrentlyAppliedFilters(null);
    props.onCloseClick();
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (filter?.uploadedBy !== `` || filter?.email !== `` || filter?.mobileNumber !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Customer Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="firstName" className="form-label">
                  First Name
                </Label>
                <Input id="firstName" name="firstName" type="text" className="form-control" value={filter?.firstName} onChange={inputChangeHandler} />
              </div>
            </Col> */}

           

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="serviceName" className="form-label">
                  Service Name
                </Label>
                <Input
                  id="serviceName"
                  name="serviceName"
                  type="text"
                  className="form-control"
                  value={filter?.serviceName}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="uploadedBy" className="form-label">
                  Coupon Uploaded By
                </Label>
                <Input id="uploadedBy" name="uploadedBy" type="text" className="form-control" value={filter?.uploadedBy} onChange={inputChangeHandler} />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="lotNumber" className="form-label">
                  Lot Number
                </Label>
                <Input id="lotNumber" name="lotNumber" type="text" className="form-control" value={filter?.lotNumber} onChange={inputChangeHandler} />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="couponStatus" className="form-label">
                  Status
                </Label>
                
                <Select
                  id="couponStatus"
                  name="couponStatus"
                  options={couponStatusOptions ?? []}
                  value={filter?.status}
                  onChange={data => inputSelectHandler(`couponStatus`, data)}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="expiryDate" className="form-label">
                  Expiry Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('expiryDate', data)}
                  value={filter?.expiryDate}
                  options={{
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
               
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="uploadedOn" className="form-label">
                  Uploaded Date
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={data => datePickHandler('uploadedOn', data)}
                  value={filter?.uploadedOn}
                  options={{
                    dateFormat: 'Y-m-d',
                    // minDate: 'today',
                  }}
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetFilterHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default UniqueCouponFilter;
