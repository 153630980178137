import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Badge, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
// import { PreviewCardHeader } from '../../../../Common'
import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import DataTable from 'react-data-table-component';

const Table = props => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => row.serialNumber,
          width: '70px',
          wrap: true,
        },
        {
          name: 'Service Id',
          selector: row => row.serviceId,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Service Name',
          selector: row => row.serviceName,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Code',
          selector: row => row.code,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Max Usage',
          selector: row => row.maxUsage,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Current Usage',
          selector: row => row.currentUsage,
          width: '200px',
        },
        {
          name: 'Remaining Usage',
          selector: row => row.remainingUsage,
          width: '200px',
        },
        {
          name: 'Created At',
          selector: row => row.createdAt,
          width: '200px',
        },
        {
          name: 'Expiry At',
          selector: row => row.expiryDate,
          width: '200px',
        },
        {
          name: 'Status',
          cell: row => addBadgeOnStatus(row?.isActive),
          width: '200px',
        },
        {
          name: 'Actions',
          width: '200px',
          cell: row => (
            <div>
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.STATIC_COUPON) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  Update
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            serviceId: data?.services?.[0]?.id || 'NA',
            serviceName: data?.services?.[0]?.name || 'NA',
            code: data?.code || `NA`,
            maxUsage: data?.maxUsage || `NA`,
            currentUsage: data?.currentUsage || `NA`,
            remainingUsage: data?.remainingUsage || `NA`,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            expiryDate: data?.expiryDate ? moment(`${data?.expiryDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            isActive: data?.isActive || `NA`,
            data: data || 'NA',
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS FLAG ---------------------------- */
  const addBadgeOnStatus = status => {
    switch (status) {
      case true:
        return <Badge color="success"> Active </Badge>;

      case false:
        return <Badge color="danger"> In-Active </Badge>;

      default:
        return <Badge color="dark"> N/A </Badge>;
    }

    // ? 'Active' : 'In-active'
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title="Coupon Data"
              primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_UPLOAD)}
              primaryButtonText={`Upload Coupon`}
              primaryClickHandler={() => props?.uploadCoupon()}
              refreshBtn={props?.data?.length > 0 ? true : false}
              refreshHandler={props.refreshHandler}
              isShowFilterBtn={true}
              filterBtnHandler={props.filterBtnHandler}
            />
              
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
              {props.children}
            </CardBody>
           
            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col className="text-end">
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      forcePage={props?.pageNumber}
                      pageCount={Math.ceil(props?.totalData / Constant.TABLE_PAGE_SIZE)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={event => {
                        props.pageHandler(event.selected);
                      }}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
