import classNames from 'classnames';
import React, { useState } from 'react';
import { Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from 'reactstrap';
import SmeOffersDashboard from './SmeOffers';
import AdibSmeDashboard from './AdibSme';

const AdibDashboard = () => {
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <div className="page-content">
      <div className="px-2 pt-2">
        <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
          <NavItem>
            <NavLink
              href="#"
              className={classNames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              SME Offers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classNames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              ADIB SME Connect
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="py-2 ps-2">
          <SmeOffersDashboard />
        </TabPane>
        <TabPane tabId="2" className="py-2 ps-2">
          <AdibSmeDashboard />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AdibDashboard;
