import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { Col, Label, Row, Input, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';

import { Constant } from '../../../../../Helpers/constant';
import { PROGRAM_USERS_LIST, PROGRAM_USER_EMPTY } from '../../../../../../store/application/actionType';
import { isValidAlphaNumbericWithoutSpace, isValidAplha, isValidNumber } from '../../../../../Helpers/Helpers';
import moment from 'moment';

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    email: ``,
    // firstName: ``,
    mobileNumber: ``,
    userName:``,
    createdAt: ``
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'firstName':
        updatedState = {
          ...filter,
          firstName: value,
        };
        setFilter(updatedState);
        break;
      case 'userName':
        updatedState = {
          ...filter,
          userName: value,
        };
        setFilter(updatedState);
        break;

      case 'email':
        updatedState = {
          ...filter,
          email: value,
        };
        setFilter(updatedState);
        break;

      case 'mobileNumber':
        updatedState = {
          ...filter,
          mobileNumber: isValidNumber(value) ? value : filter?.mobileNumber,
        };
        setFilter(updatedState);
        break;

      case 'membershipId':
        updatedState = {
          ...filter,
          membershipId: isValidAlphaNumbericWithoutSpace(value) ? value : filter?.membershipId,
        };
        setFilter(updatedState);
        break;

      case 'firstSix':
        if (value <= 999999) {
          updatedState = {
            ...filter,
            firstSix: isValidNumber(value) ? value : filter?.firstSix,
          };
          setFilter(updatedState);
        }
        break;

      case 'lastFour':
        if (value <= 9999) {
          updatedState = {
            ...filter,
            lastFour: isValidNumber(value) ? value : filter?.lastFour,
          };
          setFilter(updatedState);
        }
        break;

      case 'cardName':
        updatedState = {
          ...filter,
          cardName: isValidAplha(value) ? value : filter?.cardName,
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
  };

     const datePickHandler = (flag, date) => {
        let updatedState = null;
    
        switch (flag) {
          // case 'expiryDate':
          //   updatedState = {
          //     ...filter,
          //     expiryDate: moment(new Date(date[0])).format('YYYY-MM-DD'),
          //   };
          //   setFilter(updatedState);
          //   validationCheck(updatedState);
          //   break;
    
          case 'createdAt':
            updatedState = {
              ...filter,
              createdAt: moment(new Date(date[0])).format('YYYY-MM-DD'),
            };
            setFilter(updatedState);
            // validationCheck(updatedState);
            break;
          default:
          // do nothing
        }
      
      };
  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
    props?.setCurrentPageNumber(0);
    props.setCurrentlyAppliedFilters(null);

    props?.onReset();

    dispatch({
      type: PROGRAM_USER_EMPTY,
    });

    dispatch({
      type: PROGRAM_USERS_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Customer Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="firstName" className="form-label">
                  First Name
                </Label>
                <Input id="firstName" name="firstName" type="text" className="form-control" value={filter?.firstName} onChange={inputChangeHandler} />
              </div>
            </Col> */}

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  Email
                </Label>
                <Input id="email" name="email" type="text" className="form-control" value={filter?.email} onChange={inputChangeHandler} />
              </div>
            </Col>
            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="userName" className="form-label">
                  User Name
                </Label>
                <Input id="userName" name="userName" type="text" className="form-control" value={filter?.userName} onChange={inputChangeHandler} />
              </div>
            </Col> */}

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="mobileNumber" className="form-label">
                  Mobile Number
                </Label>
                <Input
                  id="mobileNumber"
                  name="mobileNumber"
                  type="text"
                  className="form-control"
                  value={filter?.mobileNumber}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
            <Col md={12}>
                          <div className="mb-3">
                            <Label htmlFor="createdAt" className="form-label">
                              Created Date
                            </Label>
                            <Flatpickr
                              className="form-control"
                              onChange={data => datePickHandler('createdAt', data)}
                              value={filter?.createdAt}
                              options={{
                                dateFormat: 'Y-m-d',
                               // minDate: 'today',
                              }}
                            />
                          </div>
                        </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetFilterHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
