import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ADIB_DASHBOARD } from '../../../../../../../store/actions';
import SurgeData from './Components/SurgeData';
import SummaryData from './Components/SummaryData';

type PostData = {
  month: string; // Date string for the month
  postCount: number; // Post count
};

type InputData = {
  [key: string]: PostData; // Keys are month names (e.g., "December"), and values are PostData objects
};

const AdibSmeDashboard = () => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET SURGE DATA
  const { getAdibDashboard, getAdibDashboardLoading } = useSelector((state: any) => ({
    getAdibDashboard: state?.GetAdibDashboard?.data,
    getAdibDashboardLoading: state?.GetAdibDashboard?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_ADIB_DASHBOARD,
    });
  }, []);

  console.log('getAdibDashboard', getAdibDashboard);

  // Convert nested objects to an array of objects with month names
  const convertToArray = (data: InputData) => {
    return Object.entries(data).map(([month, { month: date, postCount }]) => ({
      month, // Key name as 'month'
      date, // Nested 'month' value as 'date'
      postCount, // Nested 'postCount' value
    }));
  };

  return (
    <>
      <SurgeData
        totalRegistrations={getAdibDashboard?.data?.totalBusinessCount}
        totalPosts={getAdibDashboard?.data?.totalPostCount}
        totalOffers={getAdibDashboard?.data?.totalOfferCount}
        totalConnections={getAdibDashboard?.data?.totalConnectionCount}
        pendingApprovalRegistraions={getAdibDashboard?.data?.pendingBusinessCount}
        pendingApprovalPosts={getAdibDashboard?.data?.pendingPostCount}
        pendingApprovalOffers={getAdibDashboard?.data?.pendingOfferCount}
      />
      <SummaryData
        registrations={convertToArray(getAdibDashboard?.data?.lastThreeMonthsBusinesses ?? {})}
        posts={convertToArray(getAdibDashboard?.data?.lastThreeMonthsPosts ?? {})}
        offers={convertToArray(getAdibDashboard?.data?.lastThreeMonthsOffers ?? {})}
      />
    </>
  );
};

export default AdibSmeDashboard;
