import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL, umsUrl } from '../../../../Containers/Config/index.js';
import { DOWNLOAD_USER_REPORT, DOWNLOAD_USER_REPORT_SUCCESS, DOWNLOAD_USER_REPORT_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* getDownloadUserReport({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${umsUrl}/admin/v1/adib-download`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...payload?.headers,
        'X-Requested-By': 'ADIB_ADMIN',
        'Project-Code': 'ADMIN_ACCESS',
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      console.log('User 2 Check', response?.data?.message);
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: DOWNLOAD_USER_REPORT_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: DOWNLOAD_USER_REPORT_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_USER_REPORT_ERROR, payload: error });
  }
}

export function* watchDownloadUserReport() {
  yield takeEvery(DOWNLOAD_USER_REPORT, getDownloadUserReport);
}

function* downloadUserReportSaga() {
  yield all([fork(watchDownloadUserReport)]);
}

export default downloadUserReportSaga;
