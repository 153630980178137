import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import SurgeData from './Components/SurgeData';
import { Loader } from '../../../../../../Common';
import TakafulData from './Components/TakafulData';
import { ADIB_SURGE_REPORT, GET_ADIB_MONTHLY_SUMMARY, GET_ADIB_PARTNERS_MONTHLY_SUMMARY } from '../../../../../../../store/actions';

const SmeOffersDashboard = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [monthWiseDataForTakaful, setMonthWiseDataForTakaful] = useState([]);
  const [monthWiseDataExceptTakaful, setMonthWiseDataExceptTakaful] = useState([]);

  const [partnerMonthWiseDataForTakaful, setPartnerWiseDataForTakaful] = useState([]);
  const [partnerWiseDataExceptTakaful, setPartnerWiseDataExceptTakaful] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET SURGE DATA
  const { surgeReportData, surgeReportDataLoading } = useSelector(state => ({
    surgeReportData: state?.AdibSurgeReport?.data,
    surgeReportDataLoading: state?.AdibSurgeReport?.loading,
  }));

  // GET MONTH WISE SUMMARY DATA
  const { monthlySummary, monthlySummaryLoading } = useSelector(state => ({
    monthlySummary: state?.GetAdibMonthlySummary?.data,
    monthlySummaryLoading: state?.GetAdibMonthlySummary?.loading,
  }));

  // GET PARTNERS WISE SUMMARY DATA
  const { partnersMonthlySummary, partnersMonthlySummaryLoading } = useSelector(state => ({
    partnersMonthlySummary: state?.GetAdibPartnersMonthlySummary?.data,
    partnersMonthlySummaryLoading: state?.GetAdibPartnersMonthlySummary?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    const payloadData = {
      data: {
        dateRange: {
          from: `${moment().subtract(3, 'months').format('YYYY-MM-DD')}`,
          to: `${moment().format('YYYY-MM-DD')}`,
        },
      },
    };

    if (surgeReportData === null) {
      dispatch({
        type: ADIB_SURGE_REPORT,
        payload: {
          ...payloadData,
        },
      });
    }

    if (monthlySummary === null) {
      dispatch({
        type: GET_ADIB_MONTHLY_SUMMARY,
        payload: {
          ...payloadData,
        },
      });
    }

    if (partnersMonthlySummary === null) {
      dispatch({
        type: GET_ADIB_PARTNERS_MONTHLY_SUMMARY,
        payload: {
          ...payloadData,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (monthlySummary?.results?.length > 0) {
      const tempTakafulData = monthlySummary?.results?.filter(mData => mData?.name === `Takaful Group`);
      setMonthWiseDataForTakaful(tempTakafulData);

      const tempExceptTakafulData = monthlySummary?.results?.filter(mData => mData?.name !== `Takaful Group`);
      setMonthWiseDataExceptTakaful(tempExceptTakafulData);
    }
  }, [monthlySummary]);

  useEffect(() => {
    if (partnersMonthlySummary?.results?.length > 0) {
      const tempPartnersTakafulData = partnersMonthlySummary?.results?.filter(mData => mData?.name === `Takaful Group`);
      setPartnerWiseDataForTakaful(tempPartnersTakafulData);

      const tempPartnersExceptTakafulData = partnersMonthlySummary?.results?.filter(mData => mData?.name !== `Takaful Group`);
      setPartnerWiseDataExceptTakaful(tempPartnersExceptTakafulData);
    }
  }, [partnersMonthlySummary]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (surgeReportDataLoading || monthlySummaryLoading || partnersMonthlySummaryLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [surgeReportDataLoading, monthlySummaryLoading, partnersMonthlySummaryLoading]);

  return (
    <>
      {loading && <Loader />}

      <SurgeData
        data={{
          totalUsers: surgeReportData?.totalUsers ?? 0,
          totalBookings: surgeReportData?.noOfTotalBooking ?? 0,
          pendingBookingsCount: surgeReportData?.noOfPendingBooking ?? 0,
          completedBookingsCount: surgeReportData?.noNumberOfConfirmBookings ?? 0,
          brandsCount: surgeReportData?.bookingReport?.partnerCount ?? 0,
          categoriesCount: surgeReportData?.bookingReport?.categoriesCount ?? 0,
        }}
      />
      <TakafulData
        data={{
          monthWiseData: {
            monthlyTakafulData: monthWiseDataForTakaful ?? [],
            monthlyExceptTakafulData: monthWiseDataExceptTakaful ?? [],
            totalMonthlyTakafulData: monthlySummary?.totalTakafulCount ?? 0,
            totalMonthlyExceptTakafulData: monthlySummary?.nonTakafulCount ?? 0,
          },
          partnerWiseData: {
            partnerTakafulData: partnerMonthWiseDataForTakaful ?? [],
            partnerExceptTakafulData: partnerWiseDataExceptTakaful ?? [],
            totalPartnersTakafulData: partnersMonthlySummary?.totalTakafulCount ?? 0,
            totalPartnersExceptTakafulData: partnersMonthlySummary?.nonTakafulCount ?? 0,
          },
        }}
      />
    </>
  );
};

export default SmeOffersDashboard;
