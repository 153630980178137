import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import moment from 'moment';
import CouponInfoTable from './Components/CouponInfoTable';

import {
  GET_COUPON,
  GET_COUPONS,
  GET_COUPON_FACILITY,
  GET_COUPON_PARTNER,
  GET_COUPON_PROJECTS,
  GET_COUPON_SERVICE,
  GET_EMPTY_ASSIGN_COUPON,
  GET_EMPTY_POST_COUPON,
  GET_COUPON_EMPTY,
  UPDATE_COUPON_EMPTY,
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
} from '../../../../../store/actions';
import AssignedModal from './Components/AssignedModal';
import CouponUploadModal from './Components/CouponUploadModal';
import CouponTable from './Components/CouponTable';
import Filter from './Components/Filter/Filter';
import ChangeLotExpiry from './Components/ChangeLotExpiry';
import ChangeCouponExpiry from './Components/ChangeCouponExpiry';
import { Constant } from '../../../../Helpers/constant';
import CouponUpdateModal from './Components/UpdateCoupon';
import { indianProjectsBaseURL } from '../../../../Config';
import cogoToast from 'cogo-toast';
import UniqueCouponFilter from './Components/Filter/UniqueCouponFilter';

const UniqueCouponManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentSecondaryPageNumber, setCurrentSecondaryPageNumber] = useState(0);
  const [currentSecondaryPageIndex, setCurrentSecondaryPageIndex] = useState(0);
  const [currentSelectedCouponLot, setCurrentSelectedCouponLot] = useState(null);
  const [currentSelectedCoupon, setCurrentSelectedCoupon] = useState(null);
  const [currentAppliedSecondaryFilters, setCurrentAppliedSecondaryFilters] = useState({
    utilized: ``,
    utilizedAtFrom: ``,
    utilizedAtTo: ``,
  });

  const [isAssignedModalShow, setIsAssignedModalShow] = useState(false);
  const [isCouponUploadModalShow, setIsCouponUploadModalShow] = useState(false);
  const [isCouponUpdateModalShow, setIsCouponUpdateModalShow] = useState(false);
  const [isCouponLotsShow, setIsCouponLotsShow] = useState(true);
  const [isCouponsShow, setIsCouponsShow] = useState(false);
  const [isChangeLotExpiryShow, setIsChangeLotExpiryShow] = useState(false);
  const [isChangeExpiryShow, setIsChangeExpiryShow] = useState(false);

  const [currentlySecondaryAppliedFilters, setCurrentlySecondaryAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */

  // Coupons Lots
  const { coupons, couponsLoading, couponsError } = useSelector(state => ({
    coupons: state?.GetCoupon?.data,
    couponsLoading: state?.GetCoupon?.loading,
    couponsError: state?.GetCoupon?.error,
  }));
  console.log('coupon----',coupons);
  // Coupons
  const { couponList, couponListLoading, couponListError } = useSelector(state => ({
    couponList: state?.GetCoupons?.data,
    couponListLoading: state?.GetCoupons?.loading,
    couponListError: state?.GetCoupons?.error,
  }));

  // Update Coupon
  const { updateCoupon, updateCouponLoading } = useSelector(state => ({
    updateCoupon: state?.UpdateCoupon?.data,
    updateCouponLoading: state?.UpdateCoupon?.loading,
  }));

  // Projects
  const { couponProjects, couponProjectsLoading } = useSelector(state => ({
    couponProjects: state?.GetCouponProject?.data,
    couponProjectsLoading: state?.GetCouponProject?.loading,
  }));

  // Assign Status
  const { assignCoupon, assignCouponLoading } = useSelector(state => ({
    assignCoupon: state?.AssignCoupon?.data,
    assignCouponLoading: state?.AssignCoupon?.loading,
  }));

  // Upload Coupon
  const { postCoupons, postCouponsLoading } = useSelector(state => ({
    postCoupons: state?.PostCoupons?.data,
    postCouponsLoading: state?.PostCoupons?.loading,
  }));

  // Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  useEffect(() => {
    if (assignCoupon !== null) {
      dispatch({
        type: GET_EMPTY_ASSIGN_COUPON,
      });
      dispatch({
        type: GET_COUPON,
        payload: {
          params: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
    if (postCoupons !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });
      dispatch({
        type: GET_COUPON,
        payload: {
          params: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [dispatch, assignCoupon, postCoupons]);

  useEffect(() => {
    if (updateCoupon !== null) {
      dispatch({
        type: UPDATE_COUPON_EMPTY,
      });

      if (isCouponsShow) {
        dispatch({
          type: GET_COUPONS,
          payload: {
            urlParam: {
              CouponInfoId: currentSelectedCouponLot?.id,
              pageIndex: currentSecondaryPageNumber * Constant.TABLE_PAGE_SIZE,
            },
          },
        });
      } else if (isCouponLotsShow) {
        dispatch({
          type: GET_COUPON,
          payload: {
            params: {
              pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            },
          },
        });
      }
    }
  }, [updateCoupon]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_COUPON_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (coupons === null) {
      dispatch({
        type: GET_COUPON,
        payload: {
          params: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
    if (couponProjects === null) {
      dispatch({
        type: GET_COUPON_PROJECTS,
      });
    }
  }, [dispatch]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      couponsLoading ||
      couponProjectsLoading ||
      assignCouponLoading ||
      postCouponsLoading ||
      couponListLoading ||
      updateCouponLoading ||
      downloadDumpLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [couponsLoading, couponProjectsLoading, assignCouponLoading, postCouponsLoading, couponListLoading, updateCouponLoading, downloadDumpLoading]);

  /* ---------------------------- ASSIGNED CLICK HANDLER ---------------------------- */
  const assignClickHandler = couponId => {
    setIsAssignedModalShow(true);
    setCurrentSelectedCouponLot(couponId);
  };

  /* ---------------------------- UPLOAD COUPON BUTTON HANDLER ---------------------------- */
  const uploadCouponButtonHandler = () => {
    setIsCouponUploadModalShow(true);
  };

  /* ---------------------------- UPLOAD COUPON HANDLER ---------------------------- */
  const uploadCouponHandler = () => {
    // setIsCouponUploadModalShow(true)
  };

  /* ---------------------------- REFRESH BUTTON HANDLER ---------------------------- */
  const refreshButtonHandler = () => {
    dispatch({
      type: GET_COUPON,
      payload: {
        params: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    
    dispatch({
      type: GET_COUPON,
      payload: {
        params: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          ...currentlyAppliedFilters
        },
      },
    });
  };

  const secondaryPaginationHandler = (pageNumber, CouponInfoId) => {
    setCurrentSecondaryPageNumber(pageNumber);
    setCurrentSecondaryPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    const URLParams = {
      utilized: currentAppliedSecondaryFilters?.utilized === `` ? null : currentAppliedSecondaryFilters?.utilized,
      utilizedAtFrom:
        currentAppliedSecondaryFilters?.utilizedAtFrom === ``
          ? null
          : moment(`${currentAppliedSecondaryFilters?.utilizedAtFrom}`).format('YYYY-MM-DD'),
      utilizedAtTo:
        currentAppliedSecondaryFilters?.utilizedAtTo === `` ? null : moment(`${currentAppliedSecondaryFilters?.utilizedAtTo}`).format('YYYY-MM-DD'),
    };

    dispatch({
      type: GET_COUPONS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          CouponInfoId: currentSelectedCouponLot?.id,
          ...URLParams,
        },
      },
    });
  };

  const downloadReportHandler = couponInfoId => {
    setLoading(true);

    const url = `${indianProjectsBaseURL}/admin/coupon-hash-download?couponInfoId=${couponInfoId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        setLoading(false);
        const link = document.createElement('a');
        const fileName = 'file.xlsx';
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        setLoading(false);
        cogoToast.error('Something went wrong');
      });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedCouponLot(params?.couponDetails);

    if (params?.actionType === `ASSIGN`) {
      setIsAssignedModalShow(true);
    } else if (params?.actionType === `VIEW`) {
      setIsCouponLotsShow(false);
      setIsCouponsShow(true);
      setCurrentSecondaryPageNumber(0);

      dispatch({
        type: GET_COUPONS,
        payload: {
          urlParam: {
            pageIndex: 0,
            CouponInfoId: params?.couponDetails?.id,
          },
        },
      });
    } else if (params?.actionType === `CHANGE_LOT_EXPIRY`) {
      setIsChangeLotExpiryShow(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsCouponUpdateModalShow(true);
    } else if (params?.actionType === `DOWNLOAD`) {
      console.log('params?.couponDetails?.id', params?.couponDetails?.id);
      downloadReportHandler(params?.couponDetails?.id);
    }
  };

  const secondaryTableActionHandler = params => {
    setCurrentSelectedCoupon(params?.details);
    if (params?.actionType === `CHANGE_COUPON_EXPIRY`) {
      setIsChangeExpiryShow(true);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSecondarySubmitHandler = filters => {
    setCurrentAppliedSecondaryFilters(filters);
    setCurrentSecondaryPageNumber(0);

    const URLParams = {
      utilized: filters?.utilized === `` ? null : filters?.utilized,
      utilizedAtFrom: filters?.utilizedAtFrom === `` ? null : moment(`${filters?.utilizedAtFrom}`).format('YYYY-MM-DD'),
      utilizedAtTo: filters?.utilizedAtTo === `` ? null : moment(`${filters?.utilizedAtTo}`).format('YYYY-MM-DD'),
    };

    dispatch({
      type: GET_COUPONS,
      payload: {
        urlParam: {
          CouponInfoId: currentSelectedCouponLot?.id,
          pageIndex: 0,
          ...URLParams,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          downloadType: 5,
        },
        // data: currentlyAppliedFilters,
      },
    });
  };

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Coupon Management`) {
      setIsCouponLotsShow(true);
      setIsCouponsShow(false);
    } else if (title === `Coupons`) {
      setIsCouponLotsShow(false);
      setIsCouponsShow(true);
    }
  };

  /* ----------------------------Unique Coupon FILTERS SEC ---------------------------- */
      const filterBtnHandler = data => {
        console.log('data11:',data);
        // setCurrentlyAppliedFilters(data);
        setCurrentPageNumber(0);
      
        const urlParams = {
         
          serviceName: data?.serviceName === `` ? null : data?.serviceName,
          uploadedBy: data?.uploadedBy === `` ? null : data?.uploadedBy,
          status: data?.status === `` ? null : data?.status?.value+1,
          lotNumber: data?.lotNumber === `` ? null : data?.lotNumber,
          expiryDate: data?.expiryDate === `` ? null : data?.expiryDate,
          uploadedOn: data?.uploadedOn === `` ? null : data?.uploadedOn,
          // pageIndex: 0,
          
        };
        setCurrentlyAppliedFilters(urlParams);
        dispatch({
          type: GET_COUPON,
          payload: {
            params: {
              ...urlParams,
            },
          },
        });
      };
  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ------------------------ MODAL's  ------------------------ */}
          {isCouponUploadModalShow && <CouponUploadModal onCloseHandler={() => setIsCouponUploadModalShow(false)} />}

          {isCouponUpdateModalShow && <CouponUpdateModal data={currentSelectedCouponLot} onCloseHandler={() => setIsCouponUpdateModalShow(false)} />}

          {isAssignedModalShow && (
            <AssignedModal
              data={{
                projects: couponProjects,
                couponLogId: currentSelectedCouponLot,
              }}
              onCloseHandler={() => setIsAssignedModalShow(false)}
            />
          )}

          {isChangeLotExpiryShow && <ChangeLotExpiry data={currentSelectedCouponLot} onCloseHandler={() => setIsChangeLotExpiryShow(false)} />}

          {isChangeExpiryShow && (
            <ChangeCouponExpiry
              data={currentSelectedCoupon}
              couponLot={currentSelectedCouponLot}
              onCloseHandler={() => setIsChangeExpiryShow(false)}
            />
          )}

          {/*  ------------------------ PAGE'S  ------------------------ */}
          {isCouponLotsShow && (
            <>
              <BreadCrumb title="Coupon" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Coupon Management`]} />
              <UniqueCouponFilter show={isShowFilters} onCloseClick={()=>setIsShowFilters(false)} appliedFilters={currentlyAppliedFilters} setCurrentlyAppliedFilters={setCurrentlyAppliedFilters} filterHandler={filterBtnHandler} /> 
              <CouponInfoTable
                data={coupons?.results}
                totalData={coupons?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                downloadDummySample={{
                  status: true,
                  text: `Download Format`,
                }}
                clickHandler={uploadCouponButtonHandler}
                assignClickHandler={assignClickHandler}
                refreshHandler={refreshButtonHandler}
                dumpDownload={downloadDumpHandler}
                isShowFilters={isShowFilters}
                setIsShowFilters={setIsShowFilters}
              />
            </>
          )}

          {isCouponsShow && (
            <>
              <BreadCrumb title="Coupon" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Coupon Management`, `Coupons`]} />
              <Filter filterHandler={filterSecondarySubmitHandler} appliedFilters={currentlySecondaryAppliedFilters} />
              <CouponTable
                selectedCoupon={currentSelectedCouponLot}
                data={couponList?.results}
                totalData={couponList?.count}
                pageNumber={currentSecondaryPageNumber}
                pageHandler={secondaryPaginationHandler}
                actionHandler={secondaryTableActionHandler}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default UniqueCouponManagement;
