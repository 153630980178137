import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { BreadCrumb, Loader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';

import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  DOWNLOAD_BOOKING_V2,
  DOWNLOAD_BOOKING_V2_EMPTY,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_EMPTY,
  GET_BOOKING_LIST_V2,
  GET_BOOKING_LIST_V2_EMPTY,
} from '../../../../../../store/application/actionType';
import CancelBooking from './Components/Modals/CancelBooking';

const QRBookingManagementV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const bookingType = ['QR_BOOKING'];

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.BookingsV2?.data,
    bookingsLoading: state?.BookingsV2?.loading,
  }));

  const { cancelBooking, cancelBookingLoading } = useSelector(state => ({
    cancelBooking: state?.CancelBookingV2?.data,
    cancelBookingLoading: state?.cancelBookingV2?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDumpV2?.data,
    downloadDumpLoading: state?.DownloadDumpV2?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: currentPageNumber,
          sort_by: 'updated_at',
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });

    return () => {
      dispatch({
        type: GET_BOOKING_LIST_V2_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (cancelBooking !== null) {
      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 1,
            sort_by: 'updated_at',
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
          },
        },
      });
    }
  }, [cancelBooking]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: DOWNLOAD_BOOKING_V2_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(1);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 1,
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
            ...filters,
          },
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: 1,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });
  };

  // const closeModalHandler = (flag, data) => {
  //     if (flag === `ConfirmationModal`) {
  //       setIsShowConfirmationModal(false);
  //       if (data === `YES`) {
  //         if (currentConfirmationModalActionFor === `PROCESS`) {
  //           dispatch({
  //             type: PROCCESS_BOOKING_V2,
  //             payload: {
  //               data: {
  //                 booking_id: currentSelectedBookingDetails?.booking_id,
  //               },
  //             },
  //           });
  //         }  else if (currentConfirmationModalActionFor === `CANCEL`) {
  //           dispatch({
  //             type: UPDATE_BOOKING,
  //             payload: {
  //               data: {
  //                 bookingsData: [
  //                   {
  //                     // ...currentSelectedBookingDetails,
  //                     objectId: currentSelectedBookingDetails?.bookingId,
  //                     detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
  //                     status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  //                     process: `cancel-booking`,
  //                   },
  //                 ],
  //               },
  //             },
  //           });
  //         }
  //       }
  //     }
  //   };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber + 1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: pageNumber + 1,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_by: currentlyAppliedFilters?.sort_by ?? 'updated_at',
          sort_direction: 'DESC',
          ...currentlyAppliedFilters,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: DOWNLOAD_BOOKING_V2,
      payload: {
        data: {
          booking_type: bookingType,
          ...currentlyAppliedFilters,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (bookingsLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, downloadDumpLoading]);

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `QR Booking`) {
      console.log('');
    }
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    // setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `CANCEL`) {
      setIsCancelModalShow(true);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {isCancelModalShow && <CancelBooking onCloseHandler={() => setIsCancelModalShow(false)} data={currentSelectedBookingDetails} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="QR Booking" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `QR Booking`]} />
          <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} onReset={onResetHandler} />

          <Table
            data={bookings?.result}
            totalData={bookings?.total_count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            dumpDownload={downloadDumpHandler}
            actionHandler={tableActionHandler}
            appliedFilters={currentlyAppliedFilters}
          />
        </Container>
      </div>
    </>
  );
};

export default QRBookingManagementV2;
