import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

type SummaryDataProps = {
  registrations: { month: string; postCount: number }[];
  offers: { month: string; postCount: number }[];
  posts: { month: string; postCount: number }[];
};

const SummaryData: React.FC<SummaryDataProps> = ({ registrations, offers, posts }) => {
  const calculateTotal = (data: { month: string; postCount: number }[]) => {
    return data.reduce((total, item) => total + item.postCount, 0);
  };

  const totalRegistrations = calculateTotal(registrations);
  const totalPosts = calculateTotal(posts);
  const totalOffers = calculateTotal(offers);
  return (
    <Row>
      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Month Wise Summary (Registrations)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {registrations?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Month-Year</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody
                  style={{
                    maxHeight: '100px',
                    overflowY: 'auto',
                  }}
                >
                  {registrations?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.month ?? ''}`}</td>
                      <td>{`${data?.postCount ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <tfoot className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${totalRegistrations ?? 0}`}</th>
                  </tr>
                </tfoot>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Month Wise Summary (Posts)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {posts?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Month-Year</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody>
                  {posts?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.month ?? ''}`}</td>
                      <td>{`${data?.postCount ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <thead className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${totalPosts ?? 0}`}</th>
                  </tr>
                </thead>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Month Wise Summary (Offers)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {offers?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Month-Year</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody>
                  {offers?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.month ?? ''}`}</td>
                      <td>{`${data?.postCount ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <thead className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${totalOffers ?? 0}`}</th>
                  </tr>
                </thead>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SummaryData;
