import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Form, Row, Col, Card, CardBody, TabContent } from 'reactstrap';

import { Loader } from '../../../../../Common';
import BenefitGroupItems from './Components/Forms/BenefitGroupItems';
import Navigation from './Components/Navigation/Navigation';
import {
  CREATE_BENEFIT_GROUP_ITEM,
  CREATE_BENEFIT_GROUP_ITEM_EMPTY,
  GET_BENEFIT_GROUP_ITEMS,
  GET_BENEFIT_GROUP_ITEMS_EMPTY,
  GET_WORKFLOWS_LIST,
  UPDATE_BENEFIT_GROUP,
  UPDATE_BENEFIT_GROUP_EMPTY,
  UPDATE_BENEFIT_ITEMS,
  UPDATE_BENEFIT_ITEMS_EMPTY,
} from '../../../../../../store/application/actionType';
import EditBenefitGroup from './Components/Forms/EditBenefitGroup';

function isArrayofStrings(arr) {
  return Array.isArray(arr) && arr.every(item => typeof item === 'string');
}

const UpdateBenefitGroupsManagement = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [selectedBenefitGroup, setSelectedBenefitGroup] = useState(null);
  const [benefitConfigs, setBenefitConfigs] = useState(null);

  const [isLoadBenefitItemsTab, setIsLoadBenefitItemsTab] = useState(false);

  const [benefitGroupData, setBenefitGroupData] = useState(null);
  const [benefitGroupItemsData, setBenefitGroupItemsData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Create Benefit Group Items
  const { CreateBenefitGroupItemsRes, CreateBenefitGroupItemsLoading } = useSelector(state => ({
    CreateBenefitGroupItemsRes: state?.CreateBenefitGroupItems?.data,
    CreateBenefitGroupItemsLoading: state?.CreateBenefitGroupItems?.loading,
  }));

  // Update Benefit Group Items
  const { updateBenefitItems, updateBenefitItemsLoading } = useSelector(state => ({
    updateBenefitItems: state?.UpdateBenefitItems?.data,
    updateBenefitItemsLoading: state?.UpdateBenefitItems?.loading,
  }));

  // Update Benefit Group
  const { updateBenefitGroupRes, updateBenefitGroupLoading } = useSelector(state => ({
    updateBenefitGroupRes: state?.UpdateBenefitGroup?.data,
    updateBenefitGroupLoading: state?.UpdateBenefitGroup?.loading,
  }));

  // Get Service
  const { servicesInBenefitItemLoading } = useSelector(state => ({
    servicesInBenefitItemLoading: state?.GetServicesInBenefitItem?.loading,
  }));

  // Benefit Group Details
  const { benefitConfigsDetails, benefitConfigDetailsLoading } = useSelector(state => ({
    benefitConfigsDetails: state?.GetBenefitGroupItemsList?.data,
    benefitConfigDetailsLoading: state?.GetBenefitGroupItemsList?.loading,
  }));

  // Workflows List
  const { workflowsList, workflowsListLoading } = useSelector(state => ({
    workflowsList: state?.GetWorkflowsList?.data,
    workflowsListLoading: state?.GetWorkflowsList?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (workflowsList === null) {
      dispatch({
        type: GET_WORKFLOWS_LIST,
      });
    }
  }, []);

  useEffect(() => {
    if (props?.data != null) {
      setSelectedBenefitGroup(props?.data);
    }
  }, [props]);

  useEffect(() => {
    dispatch({
      type: UPDATE_BENEFIT_ITEMS_EMPTY,
    });

    dispatch({
      type: CREATE_BENEFIT_GROUP_ITEM_EMPTY,
    });

    if (selectedBenefitGroup?.objectId) {
      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS,
        payload: {
          urlParam: {
            pageIndex: 0,
            benefitGroupId: selectedBenefitGroup?.objectId,
          },
        },
      });
    }
  }, [updateBenefitItems, CreateBenefitGroupItemsRes]);

  useEffect(() => {
    if (updateBenefitGroupRes !== null) {
      dispatch({
        type: UPDATE_BENEFIT_GROUP_EMPTY,
      });

      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS,
        payload: {
          urlParam: {
            // pageIndex: 0,
            benefitGroupId: selectedBenefitGroup?.objectId,
          },
        },
      });
    }
  }, [updateBenefitGroupRes]);

  useEffect(() => {
    if (benefitConfigsDetails !== null) {
      setBenefitConfigs([...benefitConfigsDetails.results]);
      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS_EMPTY,
      });

      if (!isLoadBenefitItemsTab) {
        setIsLoadBenefitItemsTab(true);
        toggleTab(activeTab + 1);
      }
    }
  }, [benefitConfigsDetails]);

  /* ---------------------------- BENEFIT GROUP SUBMIT HANDLER ---------------------------- */
  const updateBenefitGroupHandler = data => {
    const payload = {
      ...data,
      benefitWorthCurrency: data?.benefitWorthCurrency?.value,
      packReleaseType: data?.packReleaseType?.value,
      groupCategory: data?.groupCategory?.value,
      membership: {
        ...data?.membership,
        expiry: data?.membership?.expiry?.value,
      },
      validityTat: {
        ...data?.validityTat,
        unit: data?.validityTat?.unit?.value,
        value: data?.validityTat?.value?.value,
      },
    };

    setSelectedBenefitGroup(payload);

    dispatch({
      type: UPDATE_BENEFIT_GROUP,
      payload: {
        data: payload,
      },
    });
  };

  /* ---------------------------- BENEFIT GROUP ITEMS SAVE HANDLER ---------------------------- */
  const benefitGroupItemsSaveHandler = data => {
    setBenefitGroupItemsData(data);
    toggleTab(activeTab + 1);
  };

  const modifiedAirportsData = airportsData => {
    if (airportsData !== null) {
      if (isArrayofStrings(airportsData)) {
        return airportsData;
      } else {
        return airportsData?.map(airport => airport?.id);
      }
    } else {
      return [];
    }
  };

  /* ---------------------------- BENEFIT GROUP ITEMS SUBMIT HANDLER ---------------------------- */
  const benefitGroupItemsSubmitHandler = data => {
    let tempOrCondition = {
      enabled: false,
    };

    let payload;
    if (data?.dynamicCode?.prefix) {
      payload = {
        ...data?.benefitGroupItem?.completeData,
        benefitGroup: {
          id: selectedBenefitGroup?.objectId,
          name: selectedBenefitGroup?.name,
        },
        dynamicCode: data?.dynamicCode,
        airports: modifiedAirportsData(data?.benefitGroupItem?.completeData?.airports ?? null),
      };
    } else {
      const {
        dynamicCode, // Exclude dynamicCode from the destructuring
        ...newPayload // Rest of the properties will be captured in newPayload
      } = {
        ...data?.benefitGroupItem?.completeData,
        benefitGroup: {
          id: selectedBenefitGroup?.objectId,
          name: selectedBenefitGroup?.name,
        },
        airports: modifiedAirportsData(data?.benefitGroupItem?.completeData?.airports ?? null),
        ...(data?.dynamicCode?.prefix ? { dynamicCode: data?.dynamicCode } : {}),
      };
      payload = newPayload;
    }

    if (data?.status === `Create`) {
      dispatch({
        type: CREATE_BENEFIT_GROUP_ITEM,
        payload: {
          data: {
            requests: [payload],
          },
        },
      });
    } else if (data?.status === `Update`) {
      dispatch({
        type: UPDATE_BENEFIT_ITEMS,
        payload: {
          data: {
            ...payload,
          },
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      CreateBenefitGroupItemsLoading ||
      servicesInBenefitItemLoading ||
      updateBenefitGroupLoading ||
      benefitConfigDetailsLoading ||
      updateBenefitItemsLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    CreateBenefitGroupItemsLoading,
    servicesInBenefitItemLoading,
    updateBenefitGroupLoading,
    benefitConfigDetailsLoading,
    updateBenefitItemsLoading,
  ]);

  return (
    <>
      {/*  ---------------------------- LOADING ----------------------------  */}
      {loading && <Loader />}

      {/*  ---------------------------- SCREEN'S ----------------------------  */}
      <>
        <Row>
          <Col xl="12">
            <Card>
              {/* <button onClick={() => console.log(selectedBenefitGroup, benefitConfigs)}>get state</button> */}
              <CardBody className="checkout-tab">
                <Form action="#">
                  <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <Navigation activeTab={activeTab} toggleTab={toggleTab} />
                  </div>
                  <TabContent activeTab={activeTab}>
                    <EditBenefitGroup
                      data={selectedBenefitGroup}
                      toggleTab={toggleTab}
                      activeTab={activeTab}
                      submitHandler={updateBenefitGroupHandler}
                    />

                    <BenefitGroupItems
                      workflowsList={workflowsList}
                      data={{
                        benefitGroup: selectedBenefitGroup,
                        benefitGroupItems: benefitConfigs,
                      }}
                      toggleTab={toggleTab}
                      activeTab={activeTab}
                      togglemodal={togglemodal}
                      toggledeletemodal={toggledeletemodal}
                      onPressNext={benefitGroupItemsSaveHandler}
                      onSubmit={benefitGroupItemsSubmitHandler}
                      breadcrumNavigation={props.breadcrumNavigation}
                    />
                  </TabContent>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </>
  );
};

export default UpdateBenefitGroupsManagement;
