import axios from 'axios';
import { removeEmptyAndNullValues } from '../../Containers/Helpers/Helpers';
import { Constant } from '../../Containers/Helpers/constant';

export default async function apiCall(config) {
  const isClient =
    (Constant.userTypes.CLIENT === Number(localStorage.getItem('userType')) || !localStorage.getItem('userType')) &&
    (Constant.PROJECT_CODE === 'ADIB_V2' ||
      Constant.PROJECT_CODE === 'ADMIN_ACCESS' ||
      !JSON.parse(window.localStorage.getItem('data'))?.program?.code);

  console.log(
    'isClient',
    isClient,
    Constant.userTypes.CLIENT === Number(localStorage.getItem('userType')) || !localStorage.getItem('userType'),
    Constant.PROJECT_CODE === 'ADIB_V2' || Constant.PROJECT_CODE === 'ADMIN_ACCESS',
    config,
    Constant.PROJECT_CODE === 'ADIB_V2' || Constant.PROJECT_CODE === 'ADMIN_ACCESS' || !localStorage.getItem('data')?.program?.code,
    JSON.parse(window.localStorage.getItem('data'))?.program?.code,
  );

  const response = await axios({
    method: `${config.method}`,
    url: `${config.url}`,
    headers: {
      ...config.headers,
    },
    params: config?.params,
    data: config.isFormData ? config.data : JSON.stringify(config.data),
    paramsSerializer: customParamsSerializer,
    withCredentials: isClient,
  })
    .then(function (response) {
      return response;
    })
    .catch(err => {
      return err;
    });
  return response;
}

const customParamsSerializer = params => {
  return Object.entries(removeEmptyAndNullValues(params))
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
      }
      return `${key}=${encodeURIComponent(value?.toString() ?? '')}`;
    })
    .join('&');
};
