import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../Containers/Config/index.js';
import { CHAUFFER_ASSIGN, CHAUFFER_ASSIGN_SUCCESS, CHAUFFER_ASSIGN_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* chaufferAssign({ payload }) {
  try {
    let url = ['HSBC_BEACH_CLUB', 'ENBD_NATIONAL_DAY', 'MASTERCARD_FAB'].includes(Constant.PROJECT_CODE)
      ? thriweMarketPlaceUaeUrl
      : thriweMarketPlaceUrl;

    const response = yield call(apiCall, {
      url: `${url}/v1/admin/chauffeur-assign/${payload?.urlParam?.bookingId}`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CHAUFFER_ASSIGN_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CHAUFFER_ASSIGN_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CHAUFFER_ASSIGN_ERROR, payload: error });
  }
}

export function* watchChaufferAssign() {
  yield takeEvery(CHAUFFER_ASSIGN, chaufferAssign);
}

function* vChaufferAssign() {
  yield all([fork(watchChaufferAssign)]);
}

export default vChaufferAssign;
