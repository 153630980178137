import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_USER_DOWNLOADS, GET_USER_DOWNLOADS_SUCCESS, GET_USER_DOWNLOADS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getUserDownloads({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/v1/adib-download`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'X-Requested-By': 'ADIB_ADMIN',
        'Project-Code': 'ADMIN_ACCESS',
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_USER_DOWNLOADS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_USER_DOWNLOADS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_DOWNLOADS_ERROR, payload: error });
  }
}

export function* watchGetUserDownloads() {
  yield takeEvery(GET_USER_DOWNLOADS, getUserDownloads);
}

function* getUserDownloadsSaga() {
  yield all([fork(watchGetUserDownloads)]);
}

export default getUserDownloadsSaga;
