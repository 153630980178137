import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody, Spinner, Button } from 'reactstrap';

import { CONFIRM_BOOKING_V2, CONFIRM_BOOKING_V2_EMPTY, CANCEL_BOOKING_V2, CANCEL_BOOKING_V2_EMPTY } from '../../../../../../../../store/actions';

const CancelBooking = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { cancelBooking, cancelBookingLoading } = useSelector(state => ({
    cancelBooking: state?.CancelBookingV2?.data,
    cancelBookingLoading: state?.cancelBookingV2?.loading,
  }));

  useEffect(() => {
    if (cancelBooking !== null) {
      onCloseHandler();
      return () => {
        dispatch({
          type: CANCEL_BOOKING_V2_EMPTY,
        });
      };
    }
  }, [cancelBooking, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    dispatch({
      type: CANCEL_BOOKING_V2,
      payload: {
        urlParams: {
          booking_id: data?.booking_id,
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to Cancel this booking?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
         
          <Button className="overlay-disable" color="danger" onClick={onCloseHandler}>
            Close
          </Button>
          {cancelBookingLoading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button className="overlay-disable" color="primary" onClick={handleYesClick}>
              Sure
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
